.react-datepicker {
  font-family: inherit;
  border-radius: 0.5rem;
  border: 1px solid #E8E8E8;
  background-color: white;
  font-size: 0.9rem;
  width: 320px; /* Increased width */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__header {
  padding: 0;
  border-bottom: none;
  background-color: white;
}

.react-datepicker__month-container {
  float: none;
  width: 100%;
}

/* Month and year header */
.react-datepicker__current-month {
  font-size: 1rem;
  color: #005C78;
  font-weight: 600;
}

/* Navigation buttons */
.react-datepicker__navigation {
  top: 1rem;
}

/* Days of week header */
.react-datepicker__day-name {
  color: #48ACCA;
  font-weight: 500;
  width: 2.5rem;
  margin: 0.2rem;
}

/* Individual day cells */
.react-datepicker__day {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin: 0.2rem;
  border-radius: 0.375rem;
  color: #005C78;
}

/* Selected state */
.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: #005C78 !important;
  color: white !important;
}

/* Hover state */
.react-datepicker__day:hover {
  background-color: rgba(72, 172, 202, 0.1) !important;
}

/* In-range state */
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #48ACCA !important;
  color: white !important;
}

/* Keyboard selected */
.react-datepicker__day--keyboard-selected {
  background-color: rgba(72, 172, 202, 0.2) !important;
  color: #005C78 !important;
}

/* Today's date */
.react-datepicker__day--today {
  font-weight: bold;
  color: #48ACCA;
}

/* Outside month */
.react-datepicker__day--outside-month {
  color: #E8E8E8;
}

/* Disable triangle pointer */
.react-datepicker__triangle {
  display: none;
}

/* Custom header styles */
.custom-header {
  padding: 1rem;
  background-color: white;
  border-bottom: 1px solid #E8E8E8;
}

.custom-header-buttons {
  display: flex;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #E8E8E8;
}

.custom-header-month {
  color: #005C78;
  font-weight: 600;
  font-size: 1.1rem;
}

.custom-range-button {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #005C78;
  background-color: white;
  border: 1px solid #E8E8E8;
  border-radius: 0.375rem;
  transition: all 0.2s;
}

.custom-range-button:hover {
  background-color: rgba(72, 172, 202, 0.1);
  border-color: #48ACCA;
}